import { Transition } from "@headlessui/react";
import { signOut } from "@wwc/firebase/auth";
import { useUser } from "@wwc/hooks/user";
import { fmtUserName } from "@wwc/utils";
import cx from "classnames";
import { useMemo, useState } from "react";
import Avatar from "react-avatar";
import { NavLink } from "react-router-dom";
import type { Route } from "../lib/routes";
import { toRoute } from "../lib/routes";
import logoUrl from "/assets/logo.svg";

const NavItem: React.FC<{ to: Route }> = (props) => {
	return (
		<NavLink
			to={toRoute(props.to)}
			className={(p) =>
				cx(
					"rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white",
					p.isActive && "bg-gray-900 text-white"
				)
			}
		>
			{props.children}
		</NavLink>
	);
};

const isDevelopment = import.meta.env.DEV;

const Nav: React.FC = () => {
	const [isOpen, setIsOpen] = useState(false);
	const user = useUser();
	const name = useMemo(() => (user ? fmtUserName(user) : undefined), [user]);
	const customClaims = user?.customClaims || ({} as Record<string, any>);

	return (
		<nav className="bg-gray-800">
			<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
				<div className="flex h-16 items-center justify-between">
					<div className="flex items-center">
						<div className="flex-shrink-0">
							<NavLink to={toRoute("home")}>
								<img
									className="h-8 w-auto"
									src={logoUrl}
									alt=""
								/>
							</NavLink>
						</div>
						<div className="hidden md:block">
							<div className="ml-10 flex items-baseline space-x-4">
								{isDevelopment && customClaims.crm && (
									<>
										<NavItem to="profileList">
											Profiles
										</NavItem>
										<NavItem to="accountList">
											Accounts
										</NavItem>
										<NavItem to="consultantList">
											Consultants
										</NavItem>
									</>
								)}
								{customClaims.crm && (
									<NavItem to="registrationList">
										Registrations
									</NavItem>
								)}
								{customClaims.report && (
									<NavItem to="reportList">Reports</NavItem>
								)}
								{customClaims.team && (
									<NavItem to="userList">Team</NavItem>
								)}
							</div>
						</div>
					</div>
					<div className="hidden md:block">
						<div className="ml-4 flex items-center md:ml-6">
							<button className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
								<span className="sr-only">
									View notifications
								</span>
								<svg
									className="h-6 w-6"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									aria-hidden="true"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
									/>
								</svg>
							</button>
							<div className="relative ml-3">
								<div>
									<button
										onClick={() => setIsOpen(!isOpen)}
										className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
										id="user-menu"
										aria-haspopup="true"
									>
										<span className="sr-only">
											Open user menu
										</span>
										<Avatar
											className="h-8 w-8 rounded-full"
											name={user?.displayName || name}
											email={user?.email || ""}
											src={user?.photoURL || ""}
											size="34"
										/>
									</button>
								</div>
								<Transition
									show={isOpen}
									enter="transition ease-out duration-100"
									enterFrom="transform opacity-0 scale-95"
									enterTo="transform opacity-100 scale-100"
									leave="transition ease-in duration-75"
									leaveFrom="transform opacity-100 scale-100"
									leaveTo="transform opacity-0 scale-95"
								>
									<div
										className="absolute right-0 z-10 z-20 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5"
										role="menu"
										aria-orientation="vertical"
										aria-labelledby="user-menu"
									>
										<span
											className="block px-4 py-2 text-sm text-gray-700"
											role="menuitem"
										>
											Hi, {name}
										</span>
										<a
											href="#"
											className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
											role="menuitem"
											onClick={signOut}
										>
											Sign out
										</a>
									</div>
								</Transition>
							</div>
						</div>
					</div>
				</div>
			</div>
		</nav>
	);
};

export default Nav;
