import { useUser } from "@wwc/hooks/user";
import { getUserType } from "@wwc/utils";
import i18next from "i18next";
import { lazy, Suspense } from "react";
import { initReactI18next } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import Auth from "./components/Auth";
import Nav from "./components/Nav";
import routes from "./lib/routes";
import Home from "./screens/Home";

const RegistrationList = lazy(() => import("./screens/RegistrationList"));
const RegistrationView = lazy(() => import("./screens/RegistrationView"));
const ProfileList = lazy(() => import("./screens/ProfileList"));
const ProfileView = lazy(() => import("./screens/ProfileView"));
const AccountList = lazy(() => import("./screens/AccountList"));
const AccountView = lazy(() => import("./screens/AccountView"));
const ConsultantList = lazy(() => import("./screens/ConsultantList"));
const ConsultantView = lazy(() => import("./screens/ConsultantView"));
const ReportList = lazy(() => import("./screens/ReportList"));
const ReportView = lazy(() => import("./screens/ReportView"));
const UserList = lazy(() => import("./screens/UserList"));

i18next.use(initReactI18next).init({
	lng: "en",
	resources: {},
});

const App: React.FC = () => {
	const user = useUser();
	const userType = getUserType(user);

	if (userType === "admin") {
		return (
			<>
				<Nav />
				<Suspense fallback="">
					<Routes>
						<Route path={routes.home} element={<Home />} />
						<Route
							path={routes.profileList}
							element={<ProfileList />}
						/>
						<Route
							path={routes.registrationList}
							element={<RegistrationList />}
						/>
						<Route
							path={routes.registrationView}
							element={<RegistrationView />}
						/>
						<Route
							path={routes.profileView}
							element={<ProfileView />}
						/>
						<Route
							path={routes.accountList}
							element={<AccountList />}
						/>
						<Route
							path={routes.accountView}
							element={<AccountView />}
						/>
						<Route
							path={routes.consultantList}
							element={<ConsultantList />}
						/>
						<Route
							path={routes.consultantView}
							element={<ConsultantView />}
						/>
						<Route
							path={routes.reportList}
							element={<ReportList />}
						/>
						<Route
							path={routes.reportView}
							element={<ReportView />}
						/>
						<Route path={routes.userList} element={<UserList />} />
					</Routes>
				</Suspense>
			</>
		);
	}
	return <Auth />;
};

export default App;
