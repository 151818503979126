import { createContext, useContext, useEffect, useState } from "react";
import { collection, onSnapshot, query } from "@wwc/firebase/firestore";
import { useUser } from "./user";

import type { Profile } from "@wwc/types";

const context = createContext<Profile[] | undefined | null>(undefined);

export const ProfilesContext: React.FC = (props) => {
	const user = useUser();
	const [items, setItems] = useState<Profile[] | undefined | null>(undefined);

	useEffect(() => {
		if (!user) {
			setItems(undefined);
			return;
		}
		const q = query(collection<Profile>("profiles"));
		const unsub = onSnapshot(
			q,
			(snapshot) => {
				if (snapshot.empty) return setItems(null);
				const docs = snapshot.docs.map((a) => ({
					...a.data(),
					id: a.id,
				}));
				setItems(docs);
			},
			(err) => console.error("/profiles/*", err)
		);
		return unsub;
	}, [user?.email]);
	return <context.Provider value={items}>{props.children}</context.Provider>;
};

export const useProfiles = () => useContext(context);
