import { useState, useEffect, createContext, useContext } from "react";
import { onIdTokenChanged, signOut } from "@wwc/firebase/auth";

import { sleep, isValidUser } from "@wwc/utils";

import type { User } from "@wwc/types";

const context = createContext<User | undefined | null>(undefined);

export const UserProvider: React.FC = (props) => {
	const [user, setUser] = useState<User | undefined | null>(undefined);
	let retries = 0;
	useEffect(() => {
		const unsubscribe = onIdTokenChanged(
			async (usr) => {
				if (usr) {
					const customClaims = (await usr.getIdTokenResult()).claims;
					const _us = { ...usr, customClaims } as User;

					if (retries >= 3) {
						signOut();
						return;
					}

					if (!_us.customClaims?.type) {
						await sleep(800);
						usr.getIdToken(true);
						retries++;
					}
					if (isValidUser(_us)) {
						setUser(_us);
						return;
					}
					setUser(null);
					return;
				}
				setUser(null);
			},
			(err) => alert(err)
		);
		return unsubscribe;
	}, []);

	return <context.Provider value={user} children={props.children} />;
};

export const useUser = () => useContext(context);
